import { React, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Reservation from "./Pages/Reservation";
import NotFound from "./Pages/NotFound";
import Outlet from "./Pages/Outlet";
import Redirector from "./Components/Redirector";
import Package from "./Pages/Package";
import Profile from "./Pages/Profile";
import Customer from "./Components/Customer";
import BookingHistory from "./Components/BookingHistory";
import RulesAndTime from "./Pages/RulesAndTime";
import Merchant from "./Components/Merchant";
import { PrismaneProvider } from "@prismane/core";
import Invitee from "./Components/Grouple";
import PopupMessage from "./Components/ScreenSize";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import MobileDate from "./Components/MobileDate";
import SwitchOutlet from "./Components/SwitchOutlet";
// import { OutletProvider } from "./Components/OutletContext";
import PersonalInfo from "./Components/PersonalInfo";
import ScrollToTop from "./Components/Scroll";
import { ThemeProvider } from "./Components/ColorContext";
import SignIn from "./Components/SignIn";
import Otp from "./Components/Otp";
import Create from "./Components/Create";
import Account from "./Components/Account";
import AllPastReservations from "./Components/AllPastReservation";
import HomePageThree from "./Pages/HomePageThree";
import { ReferrerProvider } from "./Components/RefrrerContext";
import AddOn from "./Components/AddOn";

function App({ children }) {
  const [gifPlayed, setGifPlayed] = useState(false);
  const [open, setOpen] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const gifDuration = 3500;

    const timeout = setTimeout(() => {
      setGifPlayed(true);
    }, gifDuration);

    const handleResize = () => {
      setOpen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Provider store={store}>
      <ReferrerProvider>
        <ThemeProvider>
          <PrismaneProvider>
            <div className="App">
              <Router>
                <ScrollToTop />
                {open && <PopupMessage />}
                <Routes>
                  <Route path="/" element={<Redirector />} />

                  <Route
                    path="/landingPage/Hotel1843"
                    element={<HomePageThree />}
                  />
                  <Route path="/location" element={<SwitchOutlet />} />
                  <Route
                    path="/package/:outlet/:cmpcode/:estatecode"
                    element={<Outlet />}
                  />

                  <Route
                    path="/groupSize/:outlet/:cmpcode/:estatecode/:package_code"
                    element={<RulesAndTime />}
                  />
                  <Route
                    path="/FandB/:outlet/:cmpcode/:estatecode/:package_code"
                    element={<Package />}
                  />
                  <Route
                    path="/AddOn/:outlet/:cmpcode/:estatecode/:package_code"
                    element={<AddOn />}
                  />
                  <Route
                    path="/datemobile/:outlet/:cmpcode/:estatecode/:package_code"
                    element={<MobileDate />}
                  />
                  <Route
                    path="/groupSummary/:outlet/:cmpcode/:estatecode/:package_code"
                    element={<Reservation />}
                  />
                  <Route
                    path="/groupRes/:outlet/:cmpcode/:estatecode/:package_code"
                    element={<PersonalInfo />}
                  />

                  {/* sign in & profile */}
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/otp" element={<Otp />} />
                  <Route path="/create" element={<Create />} />
                  <Route path="/account" element={<Account />} />

                  <Route path="/bookingHistory" element={<BookingHistory />} />
                  <Route
                    path="/pastReservation"
                    element={<AllPastReservations />}
                  />
                  <Route path="/profile" element={<Profile />} />

                  {/*********** After reservation pages  ****************/}
                  <Route
                    path="/groupReservation/:type/:randomReservationID/:reservationType?/:sessionID?"
                    element={<Customer />}
                  />
                  <Route
                    path="/groupMerchant/:type/:randomReservationID/:reservationType?/:sessionID?"
                    element={<Merchant />}
                  />
                  <Route
                    path="/groupInvitee/:type/:randomReservationID/:reservationType?/:sessionID?"
                    element={<Invitee />}
                  />

                  <Route path="/*" element={<NotFound />} />
                  <Route path="/not-found" element={<NotFound />} />
                </Routes>
              </Router>
            </div>
          </PrismaneProvider>
        </ThemeProvider>
      </ReferrerProvider>
    </Provider>
  );
}

export default App;
