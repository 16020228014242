import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Styles/Profile.css";
import Footer from "../Components/Footer";
import { Navbar2 } from "../Components/NavbarTwo";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import CountryFlag from "react-country-flag";
import { BiLockOpen } from "react-icons/bi";
import { SlWallet } from "react-icons/sl";
import { LuSettings2 } from "react-icons/lu";
import { IoPersonOutline } from "react-icons/io5";
import Login from "../Components/Login";
import { Navbar4 } from "../Components/NavbarFour";
import { NewNavbar } from "../Components/NewNavbar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Profile = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hostname = window.location.hostname;
  const navigateToHome = useNavigate();
  let apiUrl;
  let apiUrlTwo;

  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    apiUrl =
      "https://server.testgrouple.com/api/v1/enduser/profile/updateProfile";
    apiUrlTwo =
      "https://server.testgrouple.com/api/v1/enduser/profile/viewProfile";
  }
  if (hostname.includes("grouple.tech")) {
    apiUrl =
      "https://production.grouple.tech/api/v1/enduser/profile/updateProfile";
    apiUrlTwo =
      "https://production.grouple.tech/api/v1/enduser/profile/viewProfile";
  }

  const location = useLocation();
  const { reservationData } = location.state || {};
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    phoneNumber: "",
    note: "",
    birthday: "",
    gender: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
  });

  const isValidEmail = (email) => {
    return (
      /\S+@\S+\.\S+/.test(email) &&
      (email.endsWith("@gmail.com") ||
        email.endsWith("@yahoo.com") ||
        email.endsWith(".in"))
    );
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      note: "",
      birthday: "",
      gender: "",
    };
    const nameRegex = /^\S+$/;

    if (
      formData.firstName.trim() === "" ||
      !nameRegex.test(formData.firstName)
    ) {
      valid = false;
      errors.firstName =
        "Please enter a valid first name (one word, no spaces).";
    }

    if (formData.lastName.trim() === "" || !nameRegex.test(formData.lastName)) {
      valid = false;
      errors.lastName = "Please enter a valid last name (one word, no spaces).";
    }

    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      valid = false;
      errors.email =
        "Please enter a valid Gmail address (e.g., example@gmail.com).";
    }

    if (formData.phoneNumber.trim() === "") {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    }

    setErrorMessage(errors);
    return valid;
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(apiUrlTwo);
      if (response.status === 200) {
        const userData = response.data.data[0];
        const formattedDob = userData.customer_dob
          ? new Date(userData.customer_dob).toISOString().split("T")[0]
          : "";

        console.log("UD", userData);
        setFormData({
          firstName: userData.customer_firstName || "",
          lastName: userData.customer_lastName || "",
          email: userData.customer_email || "",
          dialCode: userData.customer_InternationalCode || "",
          phoneNumber:
            `${userData.customer_InternationalCode}${userData.customer_phone}` ||
            "",
          note: "",
          birthday: formattedDob,
          gender: userData.customer_gender,
        });
      } else {
        console.log("Unexpected response code", response.status);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const isFormValid = validateForm();

      if (!isFormValid) {
        console.error("Form has errors. Please check your inputs.");
        return;
      }

      const d = formData.dialCode.split("+");
      const phoneNumberWithoutDialCode = formData.phoneNumber.replace(d[1], "");

      const formattedData = {
        customer_fname: formData.firstName,
        customer_lname: formData.lastName,
        customer_email: formData.email,
        customer_dob: formData.birthday
          ? new Date(formData.birthday).toISOString().split("T")[0]
          : "",
        customer_InternationalCode: formData.dialCode,
        customer_phone: phoneNumberWithoutDialCode,
        customer_gender: formData.gender,
      };

      console.log(formattedData);
      const response = await axios.post(apiUrl, formattedData);
      if (response.status === 200) {
        console.log("Profile updated successfully");
        fetchUserProfile();
      } else {
        console.log("Unexpected response code", response.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const secondaryNavigation = [
    {
      name: "Personal Information",
      href: "#",
      icon: IoPersonOutline,
      current: true,
    },
    {
      name: "Login & Security",
      href: "#",
      icon: BiLockOpen,
      current: false,
      clickable: false,
    },
    {
      name: "Payments",
      href: "#",
      icon: SlWallet,
      current: false,
      clickable: false,
    },
    {
      name: "Preference",
      href: "#",
      icon: LuSettings2,
      current: false,
      clickable: false,
    },
  ];

  const [activeItem, setActiveItem] = useState("Personal Information");

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  return (
    <>
      <NewNavbar />

      <div className="profile-div">
        {/* <aside className="leftDiv">
          <nav>
            <ul className="insideLeft">
              {secondaryNavigation.map((item, index) => {
                const isFirstItem = index === 0;
                const isLastItem = index === secondaryNavigation.length - 1;
                const isClickable = item.name === "Personal Information";
                return (
                  <li key={item.name} className="itemContainer">
                    <a
                      href={item.href}
                      onClick={(e) => {
                        if (!isClickable) e.preventDefault();
                        else handleItemClick(item.name);
                      }}
                      className={`no-underline ${
                        item.name === activeItem
                          ? `currentItem ${isFirstItem ? "firstItem" : ""} ${
                              isLastItem ? "lastItem" : ""
                            }`
                          : `defaultItem ${
                              isFirstItem ? "firstItemHover" : ""
                            } ${isLastItem ? "lastItemHover" : ""}`
                      } selectedDiv`}
                      // style={{
                      //   cursor: isClickable ? "pointer" : "not-allowed",
                      //   color: isClickable ? "#4a6afe" : "#6c6c70",
                      // }}
                    >
                      <div
                        className={`iconContainer ${
                          item.name === activeItem
                            ? "currentIconContainer"
                            : "defaultIconContainer"
                        }`}
                      >
                        <item.icon
                          className={`icon ${
                            item.name === activeItem
                              ? "currentIcon"
                              : "defaultIcon"
                          }`}
                          aria-hidden="true"
                        />
                      </div>
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </aside> */}

        <div className="profile-form">
          {activeItem === "Personal Information" && (
            <form
              className="reservationFormAccount"
              onSubmit={handleFormSubmit}
            >
              <h1>Personal Information</h1>
              <h4>
                Keep your personal details up-to-date for a seamless experience.
              </h4>
              <div className="NameDivForm2">
                <div>
                  <label htmlFor="firstName">First name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) => {
                      const capitalizedValue =
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1);

                      setErrorMessage({ ...errorMessage, firstName: "" });
                      setFormData({ ...formData, firstName: capitalizedValue });
                      setIsModified(true);
                    }}
                    required
                  />
                  {errorMessage.firstName && (
                    <p style={{ color: "red" }}>{errorMessage.firstName}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) => {
                      const capitalizedValueTwo =
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1);

                      setErrorMessage({ ...errorMessage, lastName: "" });
                      setFormData({
                        ...formData,
                        lastName: capitalizedValueTwo,
                      });
                      setIsModified(true);
                    }}
                    required
                  />
                  {errorMessage.lastName && (
                    <p style={{ color: "red" }}>{errorMessage.lastName}</p>
                  )}
                </div>
              </div>

              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => {
                  setErrorMessage({ ...errorMessage, email: "" });
                  setFormData({ ...formData, email: e.target.value });
                }}
                required
              />
              {errorMessage.email && (
                <p style={{ color: "red" }}>{errorMessage.email}</p>
              )}

              <label htmlFor="phoneNumber">Phone Number</label>
              <PhoneInput
                country={"gb"}
                inputStyle={{
                  width: "100%",
                  padding: "1.4rem 1rem 1.4rem 4rem",
                  borderRadius: "0.5rem",
                  border: "2px solid #000",
                  cursor: "not-allowed",
                }}
                dropdownStyle={{
                  padding: "1.4rem",
                  height: "80rem",
                  width: "35rem",
                }}
                buttonStyle={{
                  marginTop: "0.3rem",
                  marginBottom: "0.3rem",
                  marginLeft: "0.3rem",
                  borderRight: "2px solid #000",
                  background: "#fff",
                  borderTop: "none",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  borderBottom: "none",
                  borderLeft: "none",
                }}
                name="phoneNumber"
                placeholder="Enter phone number"
                value={formData.phoneNumber}
                onChange={(value, country) => {
                  setErrorMessage({ ...errorMessage, phoneNumber: "" });
                  setFormData({
                    ...formData,
                    dialCode: country ? `+${country.dialCode}` : `+44`,
                    phoneNumber: value,
                  });
                  setIsModified(true);
                }}
                preferredCountries={["gb"]}
                defaultCountry={"gb"}
                initialValueFormat="national"
                enableAreaCodes={true}
                buttonComponent={
                  <CountryFlag dialCode={formData.dialCode} svg />
                }
              />

              {errorMessage.phoneNumber && (
                <p style={{ color: "red" }}>{errorMessage.phoneNumber}</p>
              )}

              <label htmlFor="birthday">Date of birth (Optional)</label>

              <input
                id="birthday"
                name="birthday"
                type="date"
                autoComplete="date"
                placeholder="dd/mm/yy"
                className="dateInput"
                value={formData.birthday}
                onChange={(e) => {
                  setFormData({ ...formData, birthday: e.target.value });
                  setIsModified(true);
                }}
              />

              {/* <label htmlFor="gender">Gender (Optional)</label>

              <input
                type="text"
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={(e) => {
                  setErrorMessage({ ...errorMessage, gender: "" });
                  setFormData({ ...formData, gender: e.target.value });
                }}
              /> */}

              <div className="ConfirmButtondiv2">
                <button
                  className="submit-btn"
                  type="submit"
                  style={{
                    backgroundColor: isModified ? "#4A6AFE" : "#BCBCC0",
                  }}
                >
                  Save
                </button>
              </div>
            </form>
          )}
          {activeItem === "Login & Security" && <Login />}
        </div>
      </div>

      {windowWidth >= 1024 && <Footer />}
    </>
  );
};

export default Profile;
