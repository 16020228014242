import React, { useEffect, useState, Fragment } from "react";
import Footer from "./Footer";
import "../Styles/Request.css";
import rectangle from "../Images/mokshabook.png";
import rectangle2 from "../Images/pahadibook.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import share from "../Images/sharespoc.png";
import LoadingAnimation from "../Images/LOADINGaNIMATION.gif";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import sharecard from "../Images/share.png";
import pahadicard from "../Images/pahadi 3.png";
import { Checkbox } from "antd";
import axios from "axios";
import { Navbar4 } from "./NavbarFour";
import CalicoCard from "../Images/calico.png";
import CalicoCard2 from "../Images/bigImage.png";
import calicoSpoc from "../Images/calicoSpoc.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";

const Invitee = () => {
  const [formData, setFormData] = useState({
    invitee_fname: "",
    invitee_lname: "",
    invitee_email: "",
    invitee_mobile: "",
    invitee_notes: "",
    reservation_code: "",
  });
  const [isCopied2, setIsCopied2] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSubmitted, setIsSubmitted] = useState(
    () => JSON.parse(localStorage.getItem("isSubmitted")) || false
  );
  const [submittedData, setSubmittedData] = useState(
    () => JSON.parse(localStorage.getItem("submittedData")) || null
  );
  useEffect(() => {
    localStorage.setItem("isSubmitted", JSON.stringify(isSubmitted));
  }, [isSubmitted]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (submittedData) {
      localStorage.setItem("submittedData", JSON.stringify(submittedData));
    }
  }, [submittedData]);

  let [isOpen4, setIsOpen4] = useState(false);

  function openModal4() {
    setIsOpen4(true);
  }

  function closeModal() {
    setIsCopied2(false);
    setIsOpen4(false);
  }

  const [isFoodPopupOpen, setIsFoodPopupOpen] = useState(false);
  const [isBevPopupOpen, setIsBevPopupOpen] = useState(false);

  const openPopupFood = () => {
    setIsFoodPopupOpen(true);
  };

  const openPopupBev = () => {
    setIsBevPopupOpen(true);
  };
  const closePopupFood = () => {
    setIsFoodPopupOpen(false);
  };

  const closePopupBev = () => {
    setIsBevPopupOpen(false);
  };

  const useLockBodyScroll = (isPopupOpen) => {
    useEffect(() => {
      if (isPopupOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
      return () => {
        document.body.classList.remove("overflow-hidden");
      };
    }, [isPopupOpen]);
  };

  useLockBodyScroll(isFoodPopupOpen);
  useLockBodyScroll(isBevPopupOpen);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHost = queryParams.get("isHost") === "true";

  const choiceA = new URLSearchParams(location.search).get("choiceA");
  const choiceM = new URLSearchParams(location.search).get("choiceM");
  const choiceD = new URLSearchParams(location.search).get("choiceD");
  const { randomReservationID, type } = useParams();
  const [bookingData, setbookingData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (randomReservationID) {
      const hostname = window.location.hostname;
      let apiUrl;

      if (
        hostname.includes("testgrouple.com") ||
        hostname.includes("localhost")
      ) {
        apiUrl = `https://server.testgrouple.com/api/v1/beta/enduser/viewReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl = `https://production.grouple.tech/api/v1/beta/enduser/viewReservation/${type}/${randomReservationID}`;
      }

      fetch(apiUrl)
        .then((res) => {
          if (!res.ok) {
            console.error(`Error: Received status ${res.status}`);
            navigate("/not-found");
            return null;
          }
          return res.json();
        })
        .then((data) => {
          if (data && data.status === 200 && Object.keys(data).length > 0) {
            setbookingData(data);
          } else {
            console.log("No valid data found, redirecting to not-found");
            navigate("/not-found");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          navigate("/not-found");
        });
    }
  }, [randomReservationID, type, navigate]);

  useEffect(() => {
    if (bookingData && bookingData.data && bookingData.data.reservationCode) {
      setFormData((prevState) => ({
        ...prevState,
        reservation_code: bookingData.data.reservationCode,
      }));
    }
  }, [bookingData]);

  const navbarColor = bookingData?.data?.navbar_color;

  const [loggedInUserData, setLoggedInUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    phoneNumber: "",
    birthday: "",
    gender: "",
  });

  const fetchLoggedInUserInfoTwo = async () => {
    try {
      const response = await axios.get(
        `https://server.testgrouple.com/api/v1/enduser/profile/viewProfile`
      );
      if (response.status === 200) {
        const userDataTwo = response.data.data[0];
        const formattedDob = userDataTwo.customer_dob
          ? new Date(userDataTwo.customer_dob).toISOString().split("T")[0]
          : "";

        setLoggedInUserData({
          firstName: userDataTwo.customer_firstName || "",
          lastName: userDataTwo.customer_lastName || "",
          email: userDataTwo.customer_email || "",
          dialCode: userDataTwo.customer_InternationalCode || "",
          phoneNumber:
            `${userDataTwo.customer_InternationalCode}${userDataTwo.customer_phone}` ||
            "",
          birthday: formattedDob,
          gender: userDataTwo.customer_gender,
        });
      } else {
        console.log("Unexpected response code", response.status);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    if (isHost) {
      fetchLoggedInUserInfoTwo();
    }
  }, [isHost]);

  useEffect(() => {
    if (isHost && loggedInUserData.firstName) {
      setFormData({
        invitee_fname: loggedInUserData.firstName,
        invitee_lname: loggedInUserData.lastName,
        invitee_email: loggedInUserData.email,
        invitee_mobile: loggedInUserData.phoneNumber,
        invitee_notes: "",
        reservation_code: bookingData?.data?.reservationCode || "",
      });
    }
  }, [loggedInUserData, isHost, bookingData]);

  console.log("data", bookingData);

  let beverages = bookingData?.data?.bookingFoodItemSelected?.beverage;
  const keysArray = beverages ? Object.keys(beverages) : [];

  function capitalizeAndReplace(str) {
    const words = str.split("_");
    const capitalized = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalized.join(" ");
  }
  const statusValue = bookingData?.data?.record_status;
  const displayDate = bookingData?.data?.bookingDate;
  const date = new Date(displayDate);
  let showDate;

  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  ) {
    const options = { weekday: "short", day: "numeric", month: "short" };
    showDate = date.toLocaleDateString("en-GB", options);
  }

  // /////////

  const copyToClipboard4 = () => {
    const hostingName = window.location.hostname;
    let url;
    if (
      hostingName.includes("testgrouple.com") ||
      hostingName.includes("localhost")
    ) {
      url = `https://maps.app.goo.gl/icrtXDSqxQfSFiia6`;
    }
    if (hostingName.includes("grouple.tech")) {
      url = `https://maps.app.goo.gl/icrtXDSqxQfSFiia6}`;
    }

    navigator.clipboard
      .writeText(url)
      .then(() => setIsCopied2(true))
      .catch((error) => console.error("Failed to copy to clipboard", error));
  };

  const copyToClipboard5 = () => {
    const hostingName = window.location.hostname;
    let url = bookingData?.data?.spoc?.spocNumber;

    navigator.clipboard
      .writeText(url)
      .then(() => setIsCopied2(true))
      .catch((error) => console.error("Failed to copy to clipboard", error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneInputChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      invitee_mobile: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    try {
      setSubmittedData({
        invitee_fname: formData?.invitee_fname,
        invitee_lname: formData?.invitee_lname,
      });

      const payload = {
        reservation_code: bookingData.data.reservationCode,
        invitee_fname: formData.invitee_fname,
        invitee_lname: formData.invitee_lname,
        invitee_email: formData.invitee_email,
        invitee_mobile: formData.invitee_mobile,
        invitee_notes: formData.invitee_notes,
        invitee_type: isHost ? "HOST" : "INVITEE",
        invitee_choices: selectedItemsInvitee,
      };

      const response = await axios.post(
        "https://server.testgrouple.com/api/v1/enduser/invites/addInviteeChoices",
        payload
      );

      console.log(response);
      console.log(payload);

      setFormData({
        invitee_fname: "",
        invitee_lname: "",
        invitee_email: "",
        invitee_mobile: "",
        invitee_notes: "",
        reservation_code: bookingData.data.reservationCode,
      });
      setSelectedItemsInvitee({});
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const categoryCodeToNameMap = bookingData?.data?.package_categories.reduce(
    (map, category) => {
      map[category.fnb_category_code] = category.fnb_category;
      return map;
    },
    {}
  );

  const [selectedItemsInvitee, setSelectedItemsInvitee] = useState(() => {
    const savedItems = localStorage.getItem("selectedItemsInvitee");
    return savedItems ? JSON.parse(savedItems) : {};
  });

  useEffect(() => {
    const initialSelectedItems = { ...selectedItemsInvitee };

    bookingData?.data?.package_categories.forEach((category) => {
      const categoryCode = category.fnb_category_code;
      const allowedItems = category.allowed_nos;

      if (allowedItems === -1) {
        const allItemsInCategory =
          bookingData.data.bookingFoodItemSelected.beverage[categoryCode] || [];
        initialSelectedItems[categoryCode] = allItemsInCategory;
      }
    });

    setSelectedItemsInvitee(initialSelectedItems);
    localStorage.setItem(
      "selectedItemsInvitee",
      JSON.stringify(initialSelectedItems)
    );
  }, [bookingData?.data?.package_categories]);

  const handleItemSelect = (categoryCode, item) => {
    const category = bookingData.data.package_categories.find(
      (cat) => cat.fnb_category_code === categoryCode
    );
    const allowedItems = category?.allowed_nos || 0;
    const currentSelected = selectedItemsInvitee[categoryCode] || [];

    let updatedSelection;
    const isSelected = currentSelected.some(
      (selectedItem) => selectedItem.fnb_item_code === item.fnb_item_code
    );
    if (allowedItems === -1 && isSelected) {
      return;
    }

    if (isSelected) {
      updatedSelection = currentSelected.filter(
        (selectedItem) => selectedItem.fnb_item_code !== item.fnb_item_code
      );
    } else if (allowedItems === -1) {
      updatedSelection = [...currentSelected, item];
    } else if (allowedItems === 1) {
      updatedSelection = [item];
    } else if (currentSelected.length < allowedItems) {
      updatedSelection = [...currentSelected, item];
    } else {
      alert(`You can only select ${allowedItems} item(s) from this category`);
      return;
    }

    const newSelectedItems = {
      ...selectedItemsInvitee,
      [categoryCode]: updatedSelection,
    };

    setSelectedItemsInvitee(newSelectedItems);
    localStorage.setItem(
      "selectedItemsInvitee",
      JSON.stringify(newSelectedItems)
    );
  };

  useEffect(() => {
    const savedItems = localStorage.getItem("selectedItemsInvitee");
    if (savedItems) {
      setSelectedItemsInvitee(JSON.parse(savedItems));
    }
  }, []);

  console.log(selectedItemsInvitee);

  const renderCategoryHeader = (categoryCode) => {
    const category = bookingData.data.package_categories.find(
      (cat) => cat.fnb_category_code === categoryCode
    );

    const categoryName = category?.fnb_category || categoryCode;
    const allowedNos = category?.allowed_nos ?? 0;
    const selectedCount = (selectedItemsInvitee[categoryName] ?? []).length;

    if (allowedNos !== undefined) {
      if (allowedNos === -1) {
        return selectedCount > 0
          ? `${categoryName} (${selectedCount} / Unlimited)`
          : `${categoryName} (Unlimited)`;
      } else {
        return selectedCount > 0
          ? `${categoryName} (${selectedCount} / ${allowedNos})`
          : `${categoryName} (Choice of ${allowedNos})`;
      }
    }

    return categoryName;
  };

  const renderCategoryItems = (categoryCode, categoryItems) => {
    if (!categoryItems || categoryItems.length === 0) {
      return null;
    }

    const category = bookingData.data.package_categories.find(
      (cat) => cat.fnb_category_code === categoryCode
    );
    const allowedItems = category?.allowed_nos || 0;

    return (
      <div key={categoryCode}>
        <div className="menuCategoryHeading">
          {renderCategoryHeader(categoryCode)}
        </div>
        {categoryItems.map((item) => (
          <div
            key={item.fnb_item_code}
            className="menuItemNameWrapper flex align-middle justify-between pr-8"
            onClick={() => handleItemSelect(categoryCode, item)}
            style={{ cursor: "pointer" }}
          >
            <div className="flex gap-2 align-middle">
              <div>
                {item.fnb_type === "NON-VEG" ? (
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      rx="1.5"
                      stroke="#963415"
                    />
                    <path
                      d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                      fill="#963415"
                    />
                  </svg>
                ) : (
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      rx="1.5"
                      stroke="#009600"
                    />
                    <circle cx="8" cy="8" r="4" fill="#009600" />
                  </svg>
                )}
              </div>
              <div>{item.fnb_item_name}</div>
            </div>

            <div>
              <input
                type={allowedItems === 1 ? "radio" : "checkbox"}
                name={`category-${categoryCode}`}
                checked={selectedItemsInvitee[categoryCode]?.some(
                  (selectedItem) =>
                    selectedItem.fnb_item_code === item.fnb_item_code
                )}
                onChange={(e) => {
                  e.stopPropagation();
                  handleItemSelect(categoryCode, item);
                }}
                className="appearance-none w-5 h-5 border-1 border-gray-400 rounded-md checked:bg-groupleTheme checked:border-groupleTheme focus:outline-none"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderMenu = (menuData, categoryType) => {
    if (!menuData || Object.keys(menuData).length === 0) {
      return null;
    }
    const categories = Object.keys(menuData).map((categoryCode) => {
      const category = bookingData.data.package_categories.find(
        (cat) => cat.fnb_category_code === categoryCode
      );
      return {
        categoryCode,
        categoryName: category?.fnb_category || "Unknown Category",
        items: menuData[categoryCode],
      };
    });
    const categorySummary = categories
      .map((cat) => {
        const selectedCount = (selectedItemsInvitee[cat.categoryCode] ?? [])
          .length;
        if (selectedCount === 0) {
          return `${cat.categoryName}`;
        }
        return `${selectedCount} ${cat.categoryName}`;
      })
      .join(", ");

    return (
      <div className="w-full mt-3 rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`foodMenuDropdownButton flex w-full justify-between ${
                  open ? "border-2 border-black" : ""
                }`}
              >
                <span>{categorySummary}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-black-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="dropdownMenuWrapper">
                {categories.map((cat) =>
                  renderCategoryItems(cat.categoryCode, cat.items)
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  };

  // console.log(selectedItemsInvitee);

  const imgpath15 = "/Imagess/Calico/calicoShare.png";
  const logo = "/Imagess/Calico/calicoLogo.png";

  if (!bookingData || (isHost && !loggedInUserData.firstName)) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <img src={LoadingAnimation} alt="Loading......" />
      </p>
    );
  }

  const foodItems = bookingData?.data?.bookingFoodItemSelected?.food;
  const beverageItems = bookingData?.data?.bookingFoodItemSelected?.beverage;

  const hasFood = foodItems && Object.keys(foodItems).length > 0;
  const hasBeverage = beverageItems && Object.keys(beverageItems).length > 0;

  return (
    <div>
      {/* <nav
        className="navbar-div-res"
        style={{ display: "flex", background: bookingData?.data?.navbar_color }}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to="/location" style={{ textDecoration: "none" }}>
            <img src={logo} alt="" className="barbaar-logo-res" />
          </Link>
        </div>
      </nav> */}

      <Navbar4 navbarColor={navbarColor} />

      <div className="RequestWrapper">
        {/* LEFT */}
        <div className="RequestWrapperLeft">
          {/* <div className="text-[#242426] text-lg font-normal font-['Gilroy-Medium'] tracking-tight">
            Reservation ID :#{bookingData.data.reservationCode}{" "}
          </div> */}

          <div className="RequestStatusWrapper">
            <div className="RequestSuccess">
              {!isSubmitted ? (
                <div>
                  {isHost ? (
                    <div>Your RSVP 🎉</div>
                  ) : (
                    <div>Your Dining Invitation 🎉</div>
                  )}
                </div>
              ) : (
                <div className="text-[#242426] text-[32px] font-normal font-['Gilroy-SemiBold'] leading-[42px] tracking-wide">
                  {submittedData?.invitee_fname} {submittedData?.invitee_lname}
                </div>
              )}
            </div>

            {!isSubmitted ? (
              <>
                <p className="RequestStatusDetails">
                  {bookingData.data.bookerFirstName}{" "}
                  {bookingData.data.bookerLastName} has made a reservation, to
                  join a delightful experience, exquisite dining, laughter, and
                  unforgettable memories.
                </p>

                <p className="RequestStatusDetails">
                  Please fill the below information to confirm your presence and
                  see more details of the event.
                </p>
              </>
            ) : (
              <p className="RequestStatusDetails">
                Thank you for accepting the invitation! We are delighted to have
                you to join us Looking forward
              </p>
            )}

            {/* mobile price div */}
            <div className="cardMobile">
              <div className="cardMobileChild">
                <img
                  className="resCardImgOne1"
                  // src={`${process.env.PUBLIC_URL}${imgpath15}`}
                  src={CalicoCard}
                  alt=""
                />
                <div>
                  <div className="mobileOutletHeader">
                    {" "}
                    {bookingData.data.bookingOutlet}
                  </div>
                  <h4> {bookingData.data.bookingOutletAddress}</h4>
                  <h4> {bookingData.data.bookingPackageCategory}</h4>
                </div>
              </div>
              <div className="PackageNamexyz">
                {bookingData?.data?.bookingPackage}
              </div>
              <div className="cardMobileParent">
                {showDate}
                <div>
                  {bookingData?.data?.bookingSlotStartTime} -
                  {bookingData?.data?.bookingSlotEndTime}{" "}
                </div>
                <div> {bookingData?.data?.bookingTotalGuest} guests </div>
              </div>
              <div className="cardMobileBottom">
                <div>Reservation ID </div>
                <div>#{bookingData?.data?.reservationCode}</div>
              </div>
            </div>
          </div>

          {!isSubmitted ? (
            <form className="inviteForm" onSubmit={handleSubmit}>
              <h2>Personal details</h2>

              <div className="inviteInnerForm">
                <div>
                  <h4>First name</h4>
                  <input
                    type="text"
                    name="invitee_fname"
                    value={formData.invitee_fname}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div>
                  <h4>Last name</h4>
                  <input
                    type="text"
                    name="invitee_lname"
                    value={formData.invitee_lname}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="inviteInnerForm">
                <div>
                  <h4>
                    Email <span>(Optional)</span>
                  </h4>
                  <input
                    type="email"
                    name="invitee_email"
                    value={formData.invitee_email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="inviteInnerForm">
                <div>
                  <h4>Mobile</h4>
                  {/* <input
                    type="text"
                    name="invitee_mobile"
                    value={formData.invitee_mobile}
                    onChange={handleInputChange}
                    required
                  /> */}

                  <PhoneInput
                    country={"gb"}
                    inputStyle={{
                      width: "100%",
                      height: "3rem",
                      borderRadius: "0.5rem",
                      border: "1px solid #a6a6a6",
                    }}
                    dropdownStyle={{
                      padding: "1rem",
                      height: "80rem",
                      width: "36.5rem",
                      borderRadius: "0.5rem",
                    }}
                    buttonStyle={{
                      marginTop: "0.3rem",
                      marginBottom: "0.3rem",
                      borderRight: "2px solid #293897",
                      background: "#fff",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                    // name="invitee_mobile"
                    placeholder="Enter your phone number"
                    value={formData.invitee_mobile}
                    onChange={handlePhoneInputChange}
                    required
                    buttonComponent={
                      <CountryFlag dialCode={formData.dialCode} svg />
                    }
                  />
                </div>
              </div>

              <>
                {/* {(bookingData?.data?.bookingFoodItemSelected?.food ||
                  bookingData?.data?.bookingFoodItemSelected?.beverage) && (
                  <div className=" w-full border-t border-gray-200 mt-4" />
                )} */}

                {/* <h2 className="pt-4">Select Food & Beverages menu </h2> */}

                <div className="desktopMenuParent py-3">
                  {hasFood && hasBeverage && (
                    <div className="CustomerFoodMenuHead">
                      Select Food & Beverages Menu
                    </div>
                  )}

                  {hasFood && (
                    <div className="CustomerFoodMenuHead2 pt-3">Food Menu</div>
                  )}
                  {/* {/ Food menu /} */}
                  {bookingData.data.bookingFoodItemSelected.food &&
                    renderMenu(
                      bookingData.data.bookingFoodItemSelected.food,
                      "F"
                    )}

                  {hasBeverage && (
                    <div className="CustomerFoodMenuHead2 pt-3">
                      Beverage Menu
                    </div>
                  )}

                  {/* {/ Beverage menu /} */}
                  {bookingData.data.bookingFoodItemSelected.beverage &&
                    renderMenu(
                      bookingData.data.bookingFoodItemSelected.beverage,
                      "B"
                    )}
                </div>

                <div className="fandbmenuMobile2 pb-1">
                  {hasFood && hasBeverage && (
                    <div className="CustomerFoodMenuHead pt-4 mb-0">
                      Select Food & Beverages Menu
                    </div>
                  )}

                  {/* Food Menu */}
                  {bookingData?.data?.bookingFoodItemSelected?.food &&
                    Object.keys(bookingData.data.bookingFoodItemSelected.food)
                      .length > 0 && (
                      <div className="pb-3 pt-4">
                        <div className="flex justify-between">
                          <h4 className="pb-1 foodmenuHeadertwo">Food Menu</h4>
                        </div>
                        <div
                          onClick={openPopupFood}
                          className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                        >
                          <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                            <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                              {Object.keys(
                                bookingData.data.bookingFoodItemSelected.food
                              )
                                .map((categoryCode) => {
                                  const count = (
                                    selectedItemsInvitee[categoryCode] ?? []
                                  ).length;
                                  const categoryName =
                                    categoryCodeToNameMap[categoryCode] ||
                                    categoryCode;
                                  return count > 0
                                    ? `${count} ${categoryName}`
                                    : categoryName;
                                })
                                .filter((item, index, self) => {
                                  return self.indexOf(item) === index;
                                })
                                .join(", ")}
                            </div>
                          </div>
                          <div>
                            <ChevronRightIcon className="h-6 w-6 text-black-500" />
                          </div>
                        </div>
                        {isFoodPopupOpen && (
                          <div className="menu-popup-background-custom">
                            <div className="menu-popup-custom">
                              <div className="sticky top-[-2rem] bg-white z-10 flex justify-between items-center p-2.5">
                                <h2>Food Menu</h2>
                                <button
                                  className="MenuPopupCloseBtn"
                                  onClick={closePopupFood}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M7 7L17 17"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7 17L17 7"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>

                              <div className="menu-content">
                                {Object.keys(
                                  bookingData.data.bookingFoodItemSelected.food
                                ).map((categoryCode) => {
                                  const category =
                                    bookingData.data.package_categories.find(
                                      (cat) =>
                                        cat.fnb_category_code === categoryCode
                                    );
                                  const allowedItems =
                                    category?.allowed_nos || 0;

                                  return (
                                    <div key={categoryCode}>
                                      <div className="w-full h-[42px] pl-1 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                        <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                          {renderCategoryHeader(categoryCode)}
                                        </div>
                                      </div>

                                      {bookingData.data.bookingFoodItemSelected.food[
                                        categoryCode
                                      ].map((item) => (
                                        <div
                                          key={item.fnb_item_code}
                                          onClick={(e) => {
                                            if (e.target.tagName !== "INPUT") {
                                              handleItemSelect(
                                                categoryCode,
                                                item
                                              );
                                            }
                                          }}
                                          className="flex items-center px-3 py-2 justify-between pr-8"
                                        >
                                          <div className="flex gap-2 items-center">
                                            <div>
                                              {item.fnb_type === "NON-VEG" ? (
                                                <svg
                                                  width="13"
                                                  height="13"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    x="0.5"
                                                    y="0.5"
                                                    width="15"
                                                    height="15"
                                                    rx="1.5"
                                                    stroke="#963415"
                                                  />
                                                  <path
                                                    d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                                    fill="#963415"
                                                  />
                                                </svg>
                                              ) : (
                                                <svg
                                                  width="13"
                                                  height="13"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    x="0.5"
                                                    y="0.5"
                                                    width="15"
                                                    height="15"
                                                    rx="1.5"
                                                    stroke="#009600"
                                                  />
                                                  <circle
                                                    cx="8"
                                                    cy="8"
                                                    r="4"
                                                    fill="#009600"
                                                  />
                                                </svg>
                                              )}
                                            </div>
                                            <div>{item.fnb_item_name}</div>
                                          </div>

                                          <div>
                                            <input
                                              type={
                                                allowedItems === 1
                                                  ? "radio"
                                                  : "checkbox"
                                              }
                                              name={`category-${categoryCode}`}
                                              checked={selectedItemsInvitee[
                                                categoryCode
                                              ]?.some(
                                                (selectedItem) =>
                                                  selectedItem.fnb_item_code ===
                                                  item.fnb_item_code
                                              )}
                                              onChange={() =>
                                                handleItemSelect(
                                                  categoryCode,
                                                  item
                                                )
                                              }
                                              className="appearance-none w-5 h-5 border-1 border-gray-400 rounded-md checked:bg-groupleTheme checked:border-groupleTheme focus:outline-none"
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                  {/* Beverage Menu */}
                  {bookingData?.data?.bookingFoodItemSelected?.beverage &&
                    Object.keys(
                      bookingData.data.bookingFoodItemSelected.beverage
                    ).length > 0 && (
                      <>
                        <h4 className="pb-1 foodmenuHeadertwo">
                          Beverage Menu
                        </h4>
                        <div
                          onClick={openPopupBev}
                          className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                        >
                          <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                            {Object.keys(
                              bookingData.data.bookingFoodItemSelected.beverage
                            )
                              .map((categoryCode) => {
                                const count = (
                                  selectedItemsInvitee[categoryCode] ?? []
                                ).length;
                                const categoryName =
                                  categoryCodeToNameMap[categoryCode] ||
                                  categoryCode;
                                return count > 0
                                  ? `${count} ${categoryName}`
                                  : categoryName;
                              })
                              .filter((item, index, self) => {
                                return self.indexOf(item) === index;
                              })
                              .join(", ")}
                          </div>
                          <div>
                            <ChevronRightIcon className="h-6 w-6 text-black-500" />
                          </div>
                        </div>
                        {isBevPopupOpen && (
                          <div className="menu-popup-background-custom">
                            <div className="menu-popup-custom">
                              <div className="sticky top-[-2rem] bg-white z-10 flex justify-between items-center p-2.5">
                                <h2>Beverage Menu</h2>
                                <button
                                  className="MenuPopupCloseBtn"
                                  onClick={closePopupBev}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M7 7L17 17"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7 17L17 7"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>

                              <div className="menu-content">
                                {Object.keys(
                                  bookingData.data.bookingFoodItemSelected
                                    .beverage
                                ).map((categoryCode) => {
                                  const category =
                                    bookingData.data.package_categories.find(
                                      (cat) =>
                                        cat.fnb_category_code === categoryCode
                                    );
                                  const allowedItems =
                                    category?.allowed_nos || 0;

                                  return (
                                    <div key={categoryCode}>
                                      <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                        <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                          {renderCategoryHeader(categoryCode)}
                                        </div>
                                      </div>

                                      {bookingData.data.bookingFoodItemSelected.beverage[
                                        categoryCode
                                      ].map((item) => (
                                        <div
                                          key={item.fnb_item_code}
                                          className="menuItemNameWrapper flex items-center px-3 py-2 justify-between pr-8"
                                          onClick={(e) => {
                                            // Prevent click from triggering when clicking on the input
                                            if (e.target.tagName !== "INPUT") {
                                              handleItemSelect(
                                                categoryCode,
                                                item
                                              );
                                            }
                                          }}
                                        >
                                          <div className="flex gap-2 items-center">
                                            <div>
                                              {item.fnb_type === "NON-VEG" ? (
                                                <svg
                                                  width="13"
                                                  height="13"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    x="0.5"
                                                    y="0.5"
                                                    width="15"
                                                    height="15"
                                                    rx="1.5"
                                                    stroke="#963415"
                                                  />
                                                  <path
                                                    d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                                    fill="#963415"
                                                  />
                                                </svg>
                                              ) : (
                                                <svg
                                                  width="13"
                                                  height="13"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    x="0.5"
                                                    y="0.5"
                                                    width="15"
                                                    height="15"
                                                    rx="1.5"
                                                    stroke="#009600"
                                                  />
                                                  <circle
                                                    cx="8"
                                                    cy="8"
                                                    r="4"
                                                    fill="#009600"
                                                  />
                                                </svg>
                                              )}
                                            </div>
                                            <div>{item.fnb_item_name}</div>
                                          </div>

                                          <div>
                                            <input
                                              type={
                                                allowedItems === 1
                                                  ? "radio"
                                                  : "checkbox"
                                              }
                                              name={`category-${categoryCode}`}
                                              checked={selectedItemsInvitee[
                                                categoryCode
                                              ]?.some(
                                                (selectedItem) =>
                                                  selectedItem.fnb_item_code ===
                                                  item.fnb_item_code
                                              )}
                                              onChange={() =>
                                                handleItemSelect(
                                                  categoryCode,
                                                  item
                                                )
                                              }
                                              className="appearance-none w-5 h-5 border-1 border-gray-400 rounded-md checked:bg-groupleTheme checked:border-groupleTheme focus:outline-none"
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                </div>

                <div className="inviteInnerForm">
                  <div>
                    <h4>Reservation notes (Optional)</h4>
                    <textarea
                      name="invitee_notes"
                      value={formData.invitee_notes}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </>

              <div className="inviteInnerForm mb-10">
                <h5>
                  <Checkbox style={{ marginRight: "0.75rem" }} required /> By
                  clicking "Submit" you agree to{" "}
                  <Link href="https://grouple.in/privacy" target="blank">
                    <span>Terms and Conditions</span>
                  </Link>
                </h5>
              </div>

              <div className="inviteInnerForm10">
                <button
                  className="w-full hidden sm:!block rounded-lg py-3 justify-center items-center gap-2.5 text-lg font-normal font-['Gilroy-SemiBold'] tracking-tight"
                  type="submit"
                  style={{
                    backgroundColor: bookingData?.data?.button_color,
                    color: bookingData?.data?.button_text_color,
                  }}
                >
                  Submit
                </button>

                <div
                  className="fixed left-0 right-0 bottom-0 sm:hidden bg-white px-4 py-4 shadow-lg"
                  style={{
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  type="submit"
                >
                  <button
                    className="w-full rounded-lg py-3 justify-center items-center gap-2.5 inline-flex text-lg font-normal font-['Gilroy-SemiBold'] tracking-tight"
                    style={{
                      backgroundColor: bookingData?.data?.button_color,
                      color: bookingData?.data?.button_text_color,
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="ConciergeWrapper pt-0">
                <div className="ConciergeWrapperHeading">
                  Personal Concierge
                </div>
                <div className="ConciergeWrapperDetail">
                  For any questions or if you need more details about the event,
                  feel free to reach out directly to our Personal Concierge{" "}
                  <span>{bookingData.data.spoc.spocName}</span>. They're here to
                  ensure you have an amazing experience.
                </div>
                <div className="ConciergeWrapperCall" onClick={openModal4}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M4.58333 3.66699H8.25L10.0833 8.25033L7.79167 9.62533C8.77338 11.6159 10.3844 13.2269 12.375 14.2087L13.75 11.917L18.3333 13.7503V17.417C18.3333 17.9032 18.1402 18.3695 17.7964 18.7134C17.4525 19.0572 16.9862 19.2503 16.5 19.2503C12.9243 19.033 9.55181 17.5146 7.01876 14.9816C4.48571 12.4485 2.9673 9.076 2.75 5.50033C2.75 5.0141 2.94315 4.54778 3.28697 4.20396C3.63079 3.86015 4.0971 3.66699 4.58333 3.66699Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Call your personal concierge
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 15L12.5 10L7.5 5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div>
                <div style={{ paddingBottom: "2rem", marginTop: "1.5rem" }}>
                  <div className="MapHeading">
                    <h2>Getting there</h2>
                    <p>Address</p>
                    <h3>{bookingData.data.bookingOutletAddress}</h3>
                  </div>

                  {bookingData.data.bookingOutlet === "Moksha" && (
                    <iframe
                      title="Google Maps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1804.1926826016163!2d55.29822731646945!3d25.257618831496888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4375fd849943%3A0xb0a517db1521f1fe!2sMoksha%20by%20Barbaar!5e0!3m2!1sen!2sin!4v1715958283377!5m2!1sen!2sin"
                      width="100%"
                      height="300"
                      style={{ border: "0px", borderRadius: "0.5rem" }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  )}

                  {bookingData.data.bookingOutlet === "Pahadi" && (
                    <iframe
                      title="Google Maps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.384980470542!2d55.294873472981536!3d25.257631779924882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f430d63cabdf7%3A0x949944af77d22f2d!2sPahadi!5e0!3m2!1sen!2sin!4v1715956881355!5m2!1sen!2sin"
                      width="100%"
                      height="300"
                      style={{ border: "0px", borderRadius: "0.5rem" }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  )}

                  {bookingData.data.bookingOutlet ===
                    "Capella   Altair Boutique Hotel" && (
                    <iframe
                      title="Google Maps"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d53951.20713989523!2d88.38226239771032!3d22.579160962961954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275a89c490d0b%3A0xdcfe00e697c67cf1!2sAltAir%20-%20Capella!5e0!3m2!1sen!2sin!4v1718358080629!5m2!1sen!2sin"
                      width="100%"
                      height="300"
                      style={{ border: "0px", borderRadius: "0.5rem" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  )}

                  {bookingData.data.bookingOutlet ===
                    "Calico Bar And Eatery" && (
                    <iframe
                      title="Google Maps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2486.2828689683165!2d-0.9708096234128223!3d51.45296271467874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48769b3e0beb2437%3A0x6e4df44f7cd1b672!2sCalico%20Bar%20%26%20Eatery!5e0!3m2!1sen!2sin!4v1721738278549!5m2!1sen!2sin"
                      width="100%"
                      height="300"
                      style={{ border: "0px", borderRadius: "0.5rem" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  )}

                  <div
                    className="ConciergeWrapperCall"
                    onClick={copyToClipboard4}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M16.4987 7.33301H9.16536C8.67913 7.33301 8.21282 7.52616 7.869 7.86998C7.52519 8.2138 7.33203 8.68011 7.33203 9.16634V16.4997C7.33203 16.9859 7.52519 17.4522 7.869 17.796C8.21282 18.1399 8.67913 18.333 9.16536 18.333H16.4987C16.9849 18.333 17.4512 18.1399 17.7951 17.796C18.1389 17.4522 18.332 16.9859 18.332 16.4997V9.16634C18.332 8.68011 18.1389 8.2138 17.7951 7.86998C17.4512 7.52616 16.9849 7.33301 16.4987 7.33301Z"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.668 7.33366V5.50033C14.668 5.0141 14.4748 4.54778 14.131 4.20396C13.7872 3.86015 13.3209 3.66699 12.8346 3.66699H5.5013C5.01507 3.66699 4.54876 3.86015 4.20494 4.20396C3.86112 4.54778 3.66797 5.0141 3.66797 5.50033V12.8337C3.66797 13.3199 3.86112 13.7862 4.20494 14.13C4.54876 14.4738 5.01507 14.667 5.5013 14.667H7.33464"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {isCopied2
                        ? "Address Copied Succesfully!"
                        : "Copy Address"}
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M7.5 15L12.5 10L7.5 5"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* RIGHT */}
        <div className="RequestWrapperRight1">
          <div className="RequestWrapperChild1">
            <img
              className="resCardImgOne1"
              src={
                bookingData.data.bookingOutlet === "Moksha"
                  ? rectangle
                  : bookingData.data.bookingOutlet === "Calico Bar And Eatery"
                  ? CalicoCard2
                  : rectangle2
              }
              alt=""
            />

            {/* outlet addres */}
            <div className="everything-wrapper1">
              <div className="RequestDateAndTimeWrapper1">
                {bookingData.data.bookingOutlet}
              </div>

              <div className="RequestSummaryChild1">
                {bookingData.data.bookingOutletAddress}
              </div>

              <div className="RequestSummaryChild2">
                {bookingData.data.bookingPackageCategory}
              </div>
            </div>

            {/* bookin date/time/guest */}
            <div className="RequestSummaryChild3">
              <div>{showDate}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
              >
                <circle cx="1" cy="1" r="1" fill="#242426" />
              </svg>
              <div>{bookingData.data.bookingSlotStartTime} -</div>
              <div>{bookingData.data.bookingSlotEndTime}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
              >
                <circle cx="1" cy="1" r="1" fill="#242426" />
              </svg>
              <div>{bookingData.data.bookingTotalGuest} Guests</div>
            </div>

            <div className="flex justify-between w-full border-t border-[#E5E5EA] pt-3">
              <div className="ResIDTop text-[#242426] text-md font-normal font-['Gilroy-Medium'] tracking-tight">
                Reservation ID :
              </div>
              <div className="text-[#242426] text-md font-normal font-['Gilroy-Medium'] tracking-tight">
                #{bookingData?.data?.reservationCode}
              </div>
            </div>
          </div>
        </div>

        {/* *** Concierge popup ******  */}
        <Transition appear show={isOpen4} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/50" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md  rounded-lg transform overflow-hidden  bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      style={{
                        color: "#242426",
                        fontFamily: "Gilroy-SemiBold",
                        fontSize: "1.375rem",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      Contact Concierge
                      <svg
                        onClick={closeModal}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "6%",
                          top: "9%",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M24 8L8 24"
                          stroke="#242426"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 8L24 24"
                          stroke="#242426"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Dialog.Title>
                    <div className="flex column mt-3 gap-3">
                      <img
                        src={
                          bookingData.data.bookingOutlet ===
                          "Calico Bar And Eatery"
                            ? calicoSpoc
                            : share
                        }
                        alt=""
                        style={{ width: "5.5rem", height: "5.5rem" }}
                      />
                      <div style={{ marginTop: "1rem" }}>
                        <p
                          style={{
                            color: "#242426",
                            fontFamily: "Gilroy-SemiBold",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            letterSpacing: "0.02rem",
                          }}
                        >
                          {bookingData.data.spoc.spocName} <br />
                        </p>

                        <p
                          style={{
                            color: "#242426",
                            fontFamily: "Gilroy-Medium",
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            marginTop: "-0.3rem",
                            letterSpacing: "0.01rem",
                          }}
                        >
                          Concierge (SPOC)
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex flex-col w-full gap-2">
                      <button
                        style={{
                          border: "1px solid #ABABAB",
                          borderRadius: "0.5rem",
                        }}
                        onClick={copyToClipboard5}
                        type="button"
                        className="flex w-full justify-center items-center rounded-lg border border-solid border-gray-400 bg-white-500 px-4 py-2 text-sm font-medium text-black-600 focus:outline-none"
                      >
                        <svg
                          style={{ marginRight: "0.5rem" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <path
                            d="M23.625 3.5H9.625C9.39294 3.5 9.17038 3.59219 9.00628 3.75628C8.84219 3.92038 8.75 4.14294 8.75 4.375V8.75H4.375C4.14294 8.75 3.92038 8.84219 3.75628 9.00628C3.59219 9.17038 3.5 9.39294 3.5 9.625V23.625C3.5 23.8571 3.59219 24.0796 3.75628 24.2437C3.92038 24.4078 4.14294 24.5 4.375 24.5H18.375C18.6071 24.5 18.8296 24.4078 18.9937 24.2437C19.1578 24.0796 19.25 23.8571 19.25 23.625V19.25H23.625C23.8571 19.25 24.0796 19.1578 24.2437 18.9937C24.4078 18.8296 24.5 18.6071 24.5 18.375V4.375C24.5 4.14294 24.4078 3.92038 24.2437 3.75628C24.0796 3.59219 23.8571 3.5 23.625 3.5ZM17.5 22.75H5.25V10.5H17.5V22.75ZM22.75 17.5H19.25V9.625C19.25 9.39294 19.1578 9.17038 18.9937 9.00628C18.8296 8.84219 18.6071 8.75 18.375 8.75H10.5V5.25H22.75V17.5Z"
                            fill="#4A6AFE"
                          />
                        </svg>
                        {isCopied2
                          ? "Number Copied Succesfully!"
                          : `${bookingData.data.spoc.spocNumber}`}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      {windowWidth >= 1024 && <Footer />}
    </div>
  );
};

export default Invitee;
